<template>
   <div class="xuexi">
      <div class="user">
         <div class="top">
            <div class="avatar">
               <img :src="userInfo.avatar" alt="">
            </div>
            <div class="info">
               <p>{{ userInfo.userName }}</p>
               <p>{{ userInfo.customerName }}</p>
            </div>
            <div class="jifen">积分</div>
         </div>
         <div class="bottom">
            <div class="item">
               <div class="label">完成在线课程数</div>
               <div class="value"><strong>{{ userInfo.kechengnum }}</strong></div>
            </div>
            <div class="item">
               <div class="label">总学习时长<i class="el-icon-warning-outline"></i></div>
               <div class="value"><strong>{{ userInfo.totalTimes }}</strong><div class="unit">min</div></div>
            </div>
            <div class="item">
               <div class="label">本月学习时长排行<i class="el-icon-warning-outline"></i></div>
               <div class="value"><strong>{{ userInfo.timeSort }}</strong></div>
            </div>
            <div class="item">
               <div class="label">本月学习时长<i class="el-icon-warning-outline"></i></div>
               <div class="value"><strong>{{ userInfo.monthTime }}</strong><div  class="unit">min</div></div>
            </div>
         </div>
      </div>
      <div class="tab">
         <el-tabs v-model="activeName">
            <el-tab-pane label="全部任务" name="全部任务">全部任务</el-tab-pane>
            <el-tab-pane label="培训项目" name="培训项目">培训项目</el-tab-pane>
            <el-tab-pane label="新人培训" name="新人培训">新人培训</el-tab-pane>
            <el-tab-pane label="课程专题" name="课程专题">课程专题</el-tab-pane>
            <el-tab-pane label="考试" name="考试">考试</el-tab-pane>
            <el-tab-pane label="每日一练" name="每日一练">每日一练</el-tab-pane>
            <el-tab-pane label="活动" name="活动">活动</el-tab-pane>
            <el-tab-pane label="直播" name="直播">直播</el-tab-pane>
            <el-tab-pane label="面授" name="面授">面授</el-tab-pane>
         </el-tabs>
      </div>
      <div class="card-box">
         <div class="card" v-for="(item,index) in list" :key="index" @click="handleClick(item.taskType)">
            <div class="top">
               <img src="@/assets/image/icon.png" class="icon" alt="">
               <div class="progress">
                  <div class="typeName">{{ handkeTaskName(item.taskType) }}</div>
                  <el-progress :percentage="item.progress" :show-text="false"></el-progress>
               </div>
               <div class="percent">{{ item.progress }}%</div>
            </div>
            <div class="bottom">
               <div class="taskName">{{ item.taskName }}</div>
               <div class="time"><i class="el-icon-time"></i> {{ item.times }}</div>
            </div>
         </div>
      </div>
   </div>
</template>
<script>
import avatar from '@/assets/image/avatar.png'
import { computed } from 'vue'
export default {
 data(){
    return{
      userInfo: {
        avatar: avatar,
        userName: "逍遥",
        customerName: "学创联盟",
        duration: '0.61h',
        task:2,
        kechengnum:1,
        totalTimes:71,
        timeSort:1,
        monthTime:72
      },
      list:[
         {
            coverImg: "//data.51-learning.com/default/cover/1.jpg",
            taskType:12,
            progress: 0,
            taskName:'培训平台调查问卷',
            times:'15'
         },
         {
            coverImg: "//data.51-learning.com/default/cover/19.jpg",
            taskType:10,
            progress: 50,
            taskName:'新人上路',
            times:'15'
         },
         {
            coverImg: "//data.51-learning.com/default/cover/17.jpg",
            taskType:1,
            progress: 50,
            taskName:'测',
            times:'36'
         }
      ]
    }
 },
 computed:{
   handkeTaskName:function(){
      return  function(type){
         let typeName = ''
         switch(type){
            case 12:
            typeName = '活动'
            break;
            case 10:
            typeName = '培训项目'
            break;
            case 1:
            typeName = '考试'
            break;
         }
         return typeName
      }
   }
 },
 methods:{
   handleClick(taskType){
      this.$router.push({
         path: '/exam',
      })
   }
 }
}
</script>
<style lang='scss' scoped>
.xuexi{}
    .user{
      background-color: #fff;
      border-radius: 6px;
      overflow: hidden;
      margin-bottom: 24px;
      .top{
         background-image: url(../../../assets/image/xuexi_bg.png);
         background-size: cover;
         height: 90px;
         padding: 23px 26px;
         box-sizing: border-box;
         display: flex;
         justify-content: flex-start;
         align-items: center;
         flex-direction: row;
         position: relative;
         .avatar{
            width: 44px;
            height: 44px;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 0;
            position: relative;
            -webkit-user-select: none;
            -moz-user-select: none;
            user-select: none;
            border-radius: 50%;
            margin-right: 24px;
         }
         .info{
            flex:1;
         }
         .jifen{
            border: 1px solid transparent;
         }
      }
      .bottom{
         display: flex;
         justify-content: flex-start;
         align-items: unset;
         flex-direction: row;
         padding: 23px 0;
         box-sizing: border-box;
         .item{
            width: 25%;
            display: flex;
            justify-content: unset;
            align-items: center;
            flex-direction: column;
            border-right: 1px solid #e9e9e9;
            .label{
               font-size: 16px;
            }
            .value{
               margin-top: 20px;
               font-size: 26px;
               display: flex;
               align-items: end;
               .unit{
                  font-size: 12px;
                  margin-left: 6px;
               }
            }
         }
      }
    }
    .tab{
      background-color: #fff;
      border-radius: 6px;
      overflow: hidden;
      margin-bottom: 24px;
      padding: 20px 30px;
      box-sizing: border-box;
      /deep/.el-tabs__item{
         font-size: 16px;
      }
      /deep/.el-tabs__content{
         display: none;
      }
      /deep/.el-tabs__header{
         margin: 0;
      }
      /deep/.el-tabs__nav-wrap::after {
         content: "";
         position: absolute;
         left: 0;
         bottom: 0;
         width: 100%;
         height: 0;
         background-color: #E4E7ED;
         z-index: 1;
      }
    }
    .card-box{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .card{
         width: 298px;
         // margin-right: 25px;
         margin-bottom: 24px;
         height: 100px;
         border-radius: 6px;
         background-color: #fff;
         padding: 15px 18px;
         float: left;
         cursor: pointer;
         box-sizing: border-box;
         .top{
            display: flex;
            justify-content: space-between;
            align-items: unset;
            flex-direction: row;
            .icon{
               width: 32px;
               height: 32px;
            }
            .progress{
               flex: 1;
               margin: 0 20px;
               .typeName{
                  font-size: 16px;
                  margin-bottom: 10px;
               }
            }
         }
         .bottom{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 16px;
            .taskName{
               width: 160px;
               line-height: 20px;
               min-height: 20px;
               white-space: nowrap;
               text-overflow: ellipsis;
               -o-text-overflow: ellipsis;
               overflow: hidden;
               color: #202020;
               font-size: 14px;
            }
            .time{
               
               font-size: 12px;
               color: #a7a7a7;
            }
         }
      }
    }
</style>