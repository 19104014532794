<template>
    <div class="kecheng">
        <div class="title">我的课程</div>
        <div class="filter">
            <div class="radio">
                <span>状态：</span>
                <el-radio-group v-model="radio1">
                    <el-radio-button v-for="(item,index) in options" :label='item' :key="index"></el-radio-button>
                </el-radio-group>
            </div>
            <div class="serch">
                <el-input
                    placeholder="请输入课程内容"
                    suffix-icon="el-icon-search"
                    v-model="input1">
                </el-input>
            </div>
        </div>
        <div class="listbox">
            <div class="item" v-for="(item,index) in list" :key="index">
                <router-link :to="{path:'/course/detail?id=201511'}">
                    <div class="thumbnail">
                        <img :src="item.coverImg" alt="">
                        <span class="status">{{ item.progress == 100 ? '已完成':'未完成' }}</span>
                    </div>
                    <div class="info-box">
                            <div class="item-title" >{{ item.courseName }}</div>
                        <div class="info">
                            <div class="info-left">
                                上次学习：
                                <span>{{ item.startTime || '' }}</span>
                            </div>
                            <div class="info-right">
                                <span class="finish" v-if="item.progress == 100">{{ item.progress }}%</span>
                                <span class="unfinish" v-else>{{ item.progress }}%</span>
                            </div>
                        </div>
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</template>
<script>
export default {
 data(){
    return{
        input1:'',
        radio1:'全部',
        options:["全部","未完成","已完成"],
        list:[
            {
                courseName: "TRAINGO介绍",
                coverImg: "//data.51-learning.com/data1/121/cover/157fb266-a9d6-47ef-869f-80228d1c1f14.png",
                labels: "测试标签1",
                progress: 100,
                startTime: '2023-5-16 08:31',
                type:1
            },
            {
                courseName: "解密“上帝”，你也能当“先知”：认识终端消费者",
                coverImg: "//data.51-learning.com/data1/121/cover/7a4fcbde-245f-466d-90d5-64abaa1fa426.png",
                labels: "测试标签1",
                progress: 80,
                startTime: '2023-5-16 08:31',
                type:2
            },
            {
                courseName: "TRAINGO介绍",
                coverImg: "//data.51-learning.com/data1/121/cover/157fb266-a9d6-47ef-869f-80228d1c1f14.png",
                labels: "测试标签1",
                progress: 100,
                startTime: '2023-5-16 08:31',
                type:1
            },
            {
                courseName: "解密“上帝”，你也能当“先知”：认识终端消费者",
                coverImg: "//data.51-learning.com/data1/121/cover/7a4fcbde-245f-466d-90d5-64abaa1fa426.png",
                labels: "测试标签1",
                progress: 100,
                startTime: '2023-5-16 08:31',
                type:2
            }
        ]
    }
 }
}
</script>
<style lang='scss' scoped>
.kecheng{
    padding: 30px;
    background: #fff;
    height: 100%;
    .title{
        font-size: 18px;
        line-height: 18px;
        color: #333;
        font-weight: 700;
        padding-left: 6px;
        margin: 4px 0 10px;
        border-left: 6px solid #266fff;
    }
    .filter{
        display: flex;
        justify-content: space-between;
        padding: 14px 10px;
        box-sizing: border-box;
        .radio{
            /deep/.el-radio-button__inner{
                border: none;
                padding: 0 5px;
                height: 24px;
                border-radius: 4px;
                margin-right: 35px;
                font-size: 14px;
                line-height: 24px;
            }
            /deep/.is-active .el-radio-button__inner{
               
                background:#266fff ;
            }
        }
        .serch{
            width:240px;
        }
    }
    .listbox{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .item{
            width: 280px;
            height: 260px;
            border-radius: 4px;
            background-color: #f5f5f6;
            transition: all .3s ease;
            position: relative;
            border-radius: 8px;
            margin-bottom: 22px;
            &:hover{
                transform: translateY(-8px);
                box-shadow: 0 6px 12px 2px #f0f0f0;
            }
            .thumbnail{
                width: 280px;
                height: 182px;
                position: relative;
                border-radius: 4px 4px 0 0;
                overflow: hidden;
                img{
                    width: 100%;
                    height:100%;
                }
                .status{
                    position: absolute;
                    right: 5px;
                    top: 5px;
                    background-color: rgba(0,0,0,.6);
                    color: #fff;
                    font-size: 12px;
                    line-height: 24px;
                    padding: 0 5px;
                    z-index: 2;
                    border-radius: 2px;
                }
            }
            .info-box{
                padding: 10px 10px 20px;
                justify-content: space-between;
                align-items: center;
                font-size: 14px;
                .item-title{
                    font-size: 16px;
                    line-height: 24px;
                    min-height: 24px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    -o-text-overflow: ellipsis;
                    overflow: hidden;
                    margin: 0;
                    color: rgba(0,0,0,.85);
                    font-weight: 500;
                }
                .level{
                    margin-left: 20px;
                    padding: 2px 5px;
                    background: rgba(55,125,255,.1);
                    border-radius: 4px;
                    color: #266fff;
                }
                .name{
                    flex: 1;
                    line-height: 22px;
                    min-height: 22px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    -o-text-overflow: ellipsis;
                    overflow: hidden;
                    color: #333;
                }
                .info{
                    display: flex;
                    justify-content: space-between;
                    color: #999;
                    font-size: 12px;
                    align-items: center;
                    flex-direction: row;
                    padding-top: 10px;
                    .finish{
                        color: #38cdb1;
                    }
                    .unfinish{
                        color: #f5961b;
                    }
                }
            }
        }
    }
}
</style>