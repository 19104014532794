<template>
    <div class="flex container mine">
        <aside>
          <div class="userInfo">
            <i class="el-icon-edit"></i>
            <img class="avatar" :src="userInfo.avatar" alt="">
            <div class="name">{{ userInfo.userName }}</div>
            <div class="customerName">{{ userInfo.customerName }}</div>
          </div>
          <div class="nav">
            <div v-for="(item, index) in aside" :key="index">
              <div class="module" >
                <div class="item">
                  <span>{{ item.label }}</span>
                </div>
                <ul class="menu">
                  <li class="menuItem" v-for="(i, index1) in item.children" :key="index1" :class="{'active': i.label == chooseMenu}" @click="handleMenu(i.label,index,index1)">
                    <img src="@/assets/image/kecheng.svg" alt="">
                    <img :src="i.icon" alt="">
                    <span>{{ i.label }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </aside>
        <div class="components">
          <component :is="currentView"></component>
        </div>
    </div>
</template>

<script>
import avatar from '@/assets/image/avatar.png';
import xuexi from './components/xuexi.vue';
import kecheng from './components/kecheng.vue'
export default {
  components:{xuexi,kecheng},
  data () {
    return {
      userInfo: {
        avatar: avatar,
        userName: "逍遥",
        customerName: "学创联盟",
        duration: '0.61h',
        task:2,
      },
      firstIndex:0,
      secondIndex:0,
      chooseMenu:'我的学习',
      aside:[
        {
          label:'学习中心',
          icon:'',
          children:[
            {
              label:'我的学习',
              icon:'',
              id: 'xuexi',
            },
            {
              label:'我的课程',
              icon:'',
              id:'kecheng'
            },
            {
              label:'我的面授',
              icon:'',
              id:''
            },
            {
              label:'我的考试',
              icon:'',
              id:''
            },
            {
              label:'每日一练',
              icon:'',
              id:''
            },
            {
              label:'我的活动',
              icon:'',
              id:''
            },
            {
              label:'我的证书',
              icon:'',
              id:''
            },
            {
              label:'学习轨迹',
              icon:'',
              id:''
            },
            {
              label:'我的直播',
              icon:'',
              id:''
            }
            
          ]
        },
        {
          label:'互动中心',
          icon:'',
          children:[
            {
              label:'我的评论',
              icon:'',
              id:''
            },
            {
              label:'我的收藏',
              icon:'',
              id:''
            },
            {
              label:'我的点赞',
              icon:'',
              id:''
            },
          ]
        },
        {
          label:'教务管理',
          icon:'',
          children:[
            {
              label:'作业批阅',
              icon:'',
              id:''
            },
          ]
        },
        {
          label:'员工自助',
          icon:'',
          children:[
            {
              label:'讲师审批',
              icon:'',
              id:''
            },
          ]
        }
      ]
    };
  },
  
  methods:{
    handleMenu(menu,index,index1){
      this.chooseMenu = menu
      this.firstIndex = index
      this.secondIndex = index1
    }
  },
  computed: {
    currentView() {
      console.log(this.aside[this.firstIndex])
      return this.aside[this.firstIndex].children[this.secondIndex].id;
    }
  },
}
</script>
<style lang='scss' scoped>
.mine{
  padding: 32px 0;
  display: flex;
  justify-content: space-between;
  aside{
    width: 240px;
    .userInfo{
      height: 230px;
      padding: 18px;
      border-radius: 4px;
      background-color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      line-height: 1.5;
      position: relative;
      box-sizing: border-box;
      .el-icon-edit{
        position: absolute;
        top: 18px;
        right: 16px;
        font-size: 14px;
        width: 20px;
        height: 20px;
        text-align: center;
        cursor: pointer;
      }
      .avatar{
        width: 80px;
        height: 80px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0;
        position: relative;
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none;
        margin-bottom: 16px;
      }
      .name{
        color: #333;
        font-size: 16px;
        margin-bottom: 4px;
      }
      .customerName{
        color: #999;
        font-size: 14px;
      }
    }
    .nav{
      margin-top: 16px;
      padding: 8px;
      background-color: #fff;
      border-radius: 4px;
      .module{
        .item{
          font-size: 17px;
          font-weight: 700;
          color: #333;
          line-height: 50px;
          padding-left: 13px;
          span{
            font-weight: 700;
          }
        }
        .menu{
          .menuItem{
            height: 52px;
            padding-left: 62px;
            line-height: 52px;
            font-size: 16px;
            position: relative;
            cursor: pointer;
            -webkit-user-select: none;
            -moz-user-select: none;
            user-select: none;
            border-radius: 4px;
            margin-bottom: 8px;
            &:hover{
              background: #eaf1ff;
              border-left:solid 4px #266fff;
              box-sizing: border-box;
              span{
                color: #266fff;
              }
            }
          }
          .active{
            background: #eaf1ff;
            border-left:solid 4px #266fff;
            box-sizing: border-box;
            span{
              color: #266fff;
            }
          }
        }
      }
    }
  }  
  .components{
    flex: 1;
    margin-left: 16px;
    border-radius: 4px;
    // background: #fff;
  }
}
</style>